import React from 'react';
import Select, { components } from "react-select";
import classnames from "classnames";

import AsyncSelect from 'react-select/async-creatable';
import joinClassNames from "../helpers/joinClassNames";
import { useField } from "formik";
import { useTranslate } from "../hooks/useTranslate";
import classNames from "classnames";
import { ReactComponent as TriangleShapeIcon } from "../../assets/images/shapes/triangle.svg";
import { ReactComponent as HexagonShapeIcon } from "../../assets/images/shapes/hexagon.svg";
import { ReactComponent as CircleShapeIcon } from "../../assets/images/shapes/circle.svg";

export const BiomarkerShapeTypes = {
  triangle: "1",
  hexagon: "2",
  circle: "3"
};

export const BiomarkerShapesByType = {
  [BiomarkerShapeTypes.triangle]: TriangleShapeIcon,
  [BiomarkerShapeTypes.hexagon]: HexagonShapeIcon,
  [BiomarkerShapeTypes.circle]: CircleShapeIcon
}

export default function FormikReactSelect({
                                              setFieldValue,
                                              options,
                                              name,
                                              className,
                                              value,
                                              label,
                                              placeholder,
                                              valuePipe = (item) => item.value,
                                              withError,
                                              containerClassName,
                                              backendError,
                                              additionalComponents = {},
                                              isClearable,
                                              afterOnChange = () => {
                                              },
                                              disabled = false,
                                              id
                                          }) {
    const [field, { error, touched }, { setTouched }] = useField(name);
    const [translate] = useTranslate();

    const hasError = (error && touched) || backendError;

    return (
        <section className={joinClassNames(containerClassName)}>
            {label && (
                <label
                    htmlFor={name}
                >
                    {label}
                </label>
            )}
            <Select
                options={options}
                id={id}
                name={name}
                key={name + field?.value}
                onBlur={() => setTouched(true)}
                className={joinClassNames(className, hasError && "is-invalid select-invalid")}
                placeholder={placeholder}
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: (props) => (
                        <components.DropdownIndicator {...props}>
                            <i className={classnames(
                                'mdi mdi-chevron-down text-black pointer-events-none user-select-none',
                                { 'mdi-rotate-180': props.selectProps.menuIsOpen })}
                            />
                        </components.DropdownIndicator>
                    ),
                    ...additionalComponents
                }}
                onChange={(item) => {
                    setFieldValue(name, valuePipe(item));
                    afterOnChange(valuePipe(item));
                }}
                label={label}
                value={value || options.find(item => item.value === field.value)}
                isClearable={isClearable}
                isDisabled={disabled}
            />
            {withError &&
                <span className="invalid-feedback">{translate(error, { label })}</span>
            }
        </section>
    );
}

export function FormikShapeSelect({
                                            setFieldValue,
                                            options,
                                            name,
                                            className,
                                            value,
                                            label,
                                            color,
                                            placeholder,
                                            valuePipe = (item) => item.value,
                                            withError,
                                            containerClassName,
                                            backendError,
                                            additionalComponents = {},
                                            isClearable,
                                            afterOnChange = () => {
                                            },
                                            disabled = false,
                                            id
                                          }) {
  const [field, { error, touched }, { setTouched }] = useField({ name });

  const hasError = (error && touched) || backendError;
  const Svg = BiomarkerShapesByType[field.value] || BiomarkerShapesByType[BiomarkerShapeTypes.triangle];
  return (
    <section className={joinClassNames(containerClassName)}>
      <div className={classNames("d-flex w-20 align-items-center flex-row")}>
        {label && (
          <label
            htmlFor={name}
          >
            {label}
          </label>
        )}
      </div>
      <div className="d-flex gap-3 flex-grow-1">
        <div className="flex-grow-1">
          <Select
            options={options}
            id={id}
            name={name}
            key={name + field?.value?.label}
            onBlur={() => setTouched(true)}
            className={joinClassNames(className, hasError && "is-invalid select-invalid")}
            placeholder={placeholder}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: (props) => (
                <components.DropdownIndicator {...props}>
                  <i className={classnames(
                    'mdi mdi-chevron-down text-black pointer-events-none user-select-none',
                    { 'mdi-rotate-180': props.selectProps.menuIsOpen })}
                  />
                </components.DropdownIndicator>
              ),
              ...additionalComponents
            }}
            onChange={(item) => {
              setFieldValue(name, valuePipe(item));
              afterOnChange(valuePipe(item));
            }}
            label={label}
            value={value || options.find(item => item.value === field.value )}
            isClearable={isClearable}
            isDisabled={disabled}
          />
        </div>
        <div className="flex-grow-0 d-flex align-items-center pl-0 shape-preview">
          <Svg fill={color} />
        </div>
      </div>
    </section>
);
}



export const CustomAsyncReactSelect = ({
  setFieldValue,
    name,
    className,
                                           value,
                                           label,
                                           placeholder,
                                           valuePipe = (item) => item.value,
                                           withError,
                                           containerClassName,
                                           backendError,
                                           additionalComponents = {},
                                           isClearable,
                                           afterOnChange = () => {
                                           },
                                           promiseOptions
                                       }) => {
    const [field, { error, touched }, { setTouched }] = useField({ name });
    const [translate] = useTranslate();
    const hasError = (error && touched) || backendError;

    return (
        <section className={joinClassNames(containerClassName)}>
            {label && (
                <label
                    htmlFor={name}
                >
                    {label}
                </label>
            )}
            <AsyncSelect
                id={name}
                name={name}
                key={name + value?.label}
                onBlur={() => {
                    setTouched(true);
                }}
                className={joinClassNames(className, hasError && "is-invalid select-invalid")}
                placeholder={placeholder}
                components={{
                    IndicatorSeparator: () => null,
                    LoadingIndicator: () => null,
                    ...additionalComponents
                }}
                onChange={(item) => {
                    setFieldValue(name, valuePipe(item));
                    afterOnChange(valuePipe(item));
                }}
                label={label}
                value={value}
                isClearable={isClearable}
                cacheOptions
                defaultOptions
                loadOptions={promiseOptions}
            />
            {withError &&
                <span className="invalid-feedback">{translate(error, { label })}</span>
            }
        </section>
    );
};