import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";

import Title from "../../../Biomarkers/Details/Title";
import BaseLayoutWithCard from "../../../../../base/components/BaseLayoutWithCard";
import FormikInput from "../../../../../base/components/FormikInput";
import Button from "../../../../../base/components/Button";

import ToastService from "../../../../../services/ToastService";

import { useService } from "../../../../../base/hooks/useService";
import { useQueryString } from "../../../../../base/hooks/useQueryString";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import { contentTypeOptions, initialValues, validationSchema } from "./form";
import { ORDER_MIN } from "./constants";
import { CONTENTS_GROUP_LINKS } from "../../config";
import ContentsService from "../../../../../services/ContentsService";
import { AuthorDropdown } from "../../../../../base/components/Dropdowns/AuthorDropdown/AuthorDropdown";
import { ruleActionOptions } from "../../../HealthProgram/Nutrition/CreateEditRule/form";
import FormikReactSelect from "../../../../../base/components/FormikReactSelect";
import { EXERCISE_CONTENT_FORMAT, EXERCISE_CONTENT_FORMAT_LABELS } from "../../../Fitness/ExerciseForm/constants";
import RadioButton from "../../../../../base/components/RadioButton";
import { CONTENT_ASSIGNED, CONTENT_ASSIGNED_LABELS } from "../../constants";

export function ContentForm() {
  /**
   * @type {ContentsService}
   */
  const contentsService = useService(ContentsService);
  /**
   * @type {ToastService}
   */
  const toastService = useService(ToastService);
  const navigate = useNavigate();

  const [isSubmitting, updateIsSubmitting] = useState(false);
  const [content, setContent] = useState(null);

  const { search: locationSearch } = useLocation();


  const {
    params: {
      editContentId
    }
  } = useQueryString(locationSearch);

  const afterSuccess = () => {
    toastService.success("Content has been successfully saved");
    navigate(CONTENTS_GROUP_LINKS.CONTENTS_LIST);
    updateIsSubmitting(false);
  };

  const apiFunction = (content) => {
    if (editContentId) {
      return contentsService.updateContent(editContentId, content);
    }

    return contentsService.createContent(content);
  };

  const mapFormValuesToContent = ({
    ...otherValues
  }) => {
    return {
      ...otherValues
    };
  };

  const createContent = async ({ ...otherValues }) => {
    updateIsSubmitting(true);

    apiFunction(mapFormValuesToContent({
      ...otherValues
    }))
      .then(afterSuccess)
      .finally(() => updateIsSubmitting(false));
  };

  const mapContentToForm = (content) => {
    return content;
  };

  const breadcrumbs = {
    title: editContentId ? "Edit Content" : "Create Content",
    breadcrumbItems: [
      { title: "Content", link: CONTENTS_GROUP_LINKS.CATEGORIES_LIST },
      { title: editContentId ? "Edit Content" : "Create Content" }
    ]
  };

  return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
    <Formik
      initialValues={content || initialValues}
      validationSchema={validationSchema}
      validateOnBlur
      onSubmit={createContent}
      enableReinitialize
    >
      {({ errors, handleSubmit, values, setFieldValue }) => {

        return <form
          className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
          onSubmit={handleSubmit}
        >
          <div className="w-50">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <Title
                title="General information"
              />
            </div>
            <section>
              <FormikInput
                name="title"
                label="Title"
                placeholder="Enter content title (required)"
              />

              <FormikInput
                name="subtitle"
                label="Subtitle"
                placeholder="Enter content subtitle (required)"
                containerClassName="mt-3"
              />

              <div className="mt-3">
                <AuthorDropdown
                  onChange={(value) => {
                    setFieldValue("author", value);
                  }}
                  value={values.author}
                  placeholder="Select author (required)"
                  label="Author"
                  error={errors.author}
                />
              </div>

              <FormikReactSelect
                name="contentType"
                options={contentTypeOptions}
                label="Content type"
                setFieldValue={setFieldValue}
                containerClassName="mt-3"
                placeholder="Select content type (required)"
                withError
              />

              <div className="mt-3">
                <label>Content assigned:</label>
                <div className="d-flex gap-3">
                  {Object.values(CONTENT_ASSIGNED).map((contentAssignedType) => <RadioButton
                    key={contentAssignedType}
                    label={CONTENT_ASSIGNED_LABELS[contentAssignedType]}
                    onChange={() => {
                      setFieldValue("contentAssignedType", contentAssignedType);
                    }}
                    checked={values.contentAssignedType === contentAssignedType}
                    name="contentAssignedType"
                  />)}
                </div>
              </div>

              <div className="d-flex justify-content-end mt-5">
                <Button
                  color={BUTTON_COLORS.primary}
                  type="submit"
                  disabled={!!Object.keys(errors).length || isSubmitting}
                >
                  Save content
                </Button>
              </div>
            </section>
          </div>
        </form>;
      }}
    </Formik>
  </BaseLayoutWithCard>;
}