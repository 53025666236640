import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from "react-router-dom";

import Table from "../../../../../base/components/Table";
import ConfirmPopup from "../../../../../base/components/ConfirmPopup";
import {
    SearchPlaceholder,
    TableHeader,
    columns,
    NoContentsPlaceholder,
    TAGS_ACTIONS
} from "./components";

import { DEFAULT_TABLE_LIMIT } from "../../../../../base/constants/shared";
import {
    usePaginationProvider,
    useLocationSource,
    useSearchProvider,
    useSortProvider
} from "../../../../../base/components/Table/hooks";
import ToasterService from "../../../../../services/ToastService";
import { useService } from "../../../../../base/hooks/useService";
import { useLoading } from "../../../../../base/hooks/useLoading";
import { useQueryString } from "../../../../../base/hooks/useQueryString";
import { LIMIT_OPTIONS, MIN_SEARCH_LENGTH } from "../../../../../base/constants/pagination";
import ContentsService from "../../../../../services/ContentsService";


export default function Contents() {
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);
    /**
     * @type {ContentsService}
     */
    const contentService = useService(ContentsService);
    const [isLoading, { registerPromise }] = useLoading(false);
    const { search: locationSearch, pathname } = useLocation();
    const {
        params: {
            limit = DEFAULT_TABLE_LIMIT,
            offset = 0,
            search,
            name,
        }
    } = useQueryString(locationSearch);

    const [contents, updateContents] = useState([]);
    const [pagination, updatePagination] = useState({});
    const [showDeletePopup, updateShowDeletePopup] = useState(null);

    const locationSource = useLocationSource();

    const sortKeys = ["name"];
    const getSortScope = (key) => [...sortKeys.filter(item => item !== key), "offset"];

    const nameSortProvider = useSortProvider({
        source: locationSource,
        alias: "name",
        scope: "",
        onApplyClearScope: getSortScope("name")
    });

    const searchRequest = useMemo(() => search && search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH
        ? search
        : null,
        [search])

    const paginationProvider = usePaginationProvider({
        source: locationSource,
        alias: "offset",
        scope: "",
        fallback: 0
    });

    const searchProvider = useSearchProvider({
        source: locationSource,
        scope: "",
        alias: 'search',
        onApplyClearScope: ["offset"]
    });

    const limitProvider = usePaginationProvider({
        source: locationSource,
        alias: "limit",
        scope: "",
        fallback: 10,
        onApplyClearScope: ["offset"]
    });

    const deleteContents = (id) => {
        contentService.deleteContent(id).then(() => {
            getContents()
            toastService.success("The contents has been successfully deleted")
        })
    }

    const getContents = useCallback(() => {
        const [orderBy, orderType] = Object.entries({
            name
        }).find(([_, value]) => value) || [];

        registerPromise(contentService.getContents({
            limit,
            offset,
            query: searchRequest,
            orderBy,
            orderType,
        })).then(({ data, pagination }) => {
            updateContents(data);
            updatePagination(pagination);
        });
    }, [limit, offset, searchRequest, name]);

    useEffect(() => {
        getContents();
    }, [getContents]);

    return (
        <>
            <Table
                columns={contents.length ? columns : []}
                data={contents}
                loading={isLoading}
                HeaderComponent={TableHeader}
                totalCount={pagination.totalCount}
                limit={limitProvider.getValue()}
                offset={offset}
                paginationProvider={paginationProvider}
                searchProvider={searchProvider}
                commonPlaceholder={<NoContentsPlaceholder/>}
                placeholderForSearch={<SearchPlaceholder/>}
                actions={{
                    [TAGS_ACTIONS.EDIT]: (id) => {

                    },
                    [TAGS_ACTIONS.DELETE]: updateShowDeletePopup,
                }}
                sortProviders={{
                    name: nameSortProvider,
                }}
                isLimitEditable
                limitProvider={limitProvider}
                limitOptions={LIMIT_OPTIONS}
            />
            {showDeletePopup &&
                <ConfirmPopup
                    isOpen={showDeletePopup}
                    updateIsOpen={updateShowDeletePopup}
                    onSubmit={() => {
                        deleteContents(showDeletePopup)
                        updateShowDeletePopup(null);
                    }}
                    title="Delete content"
                    description="Are you sure you want to delete the chosen content?"
                    submitBtnText="Delete"
                    className="upload-manually__popup"
                />
            }
        </>
    );
}
