import yup from "../../../../../validation/yup";
import {
    MIN_STRING_LENGTH,
} from "./constants";
import { MAX_FULL_NAME, NAME_PATTERN, ORDER_MIN } from "./constants";
export const initialValues = {
    fullName: "",
    order: null,
};

export const validationSchema = yup.object().shape({
    fullName: yup
      .string()
      .trim()
      .min(MIN_STRING_LENGTH)
      .max(MAX_FULL_NAME)
      .matches(NAME_PATTERN, "Invalid name")
      .required("Is required"),
    order: yup
      .number()
      .min(ORDER_MIN)
});