import React, { useMemo } from "react";

import Button from "../../../../../base/components/Button";
import TableSearch from "../../../../../base/components/Table/tableSearch";
import { useLocationQuery } from "../../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../../base/hooks/useHighlight";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import { MIN_SEARCH_LENGTH } from "../../../../../base/constants/pagination";
import Icon from "../../../../../base/components/Icon";
import { useNavigate } from "react-router-dom";
import { CONTENTS_GROUP_LINKS } from "../../config";

export const TAGS_ACTIONS = {
    DELETE: 'delete',
    EDIT: 'edit',
    OPEN_DETAILS: "openDetails"
};

export const SearchPlaceholder = () => (
    <>
        No matching contents.
    </>
);

export const NoContentsPlaceholder = () => (
    <div className="text-center">
        No contents for now.<br/>
        Click the “Create content” option to add a new one.
    </div>
);
export const TableHeader = ({ searchProvider }) => {
  const navigate = useNavigate()
  const goToCreate = () => {
    navigate(CONTENTS_GROUP_LINKS.FORM_CONTENTS);
  };

    return (
        <section className="d-flex w-100 pt-1 my-3 align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-3">
                <TableSearch
                    className="biomarkers-search"
                    search={searchProvider?.getValue()}
                    onSearch={searchProvider.setValue}
                    placeholder="Search content..."
                />
            </div>

            <Button color={BUTTON_COLORS.primary} onClick={goToCreate} className="d-flex align-items-center">
                <i className="bx bx-plus me-2"/>
                Create content
            </Button>
        </section>
    );
};

export const columns = [
    {
        Header: "#",
        width: 40,
        className: "bg-white",
        Cell: (cellProps) => {
            const { params: { offset = 0 } } = useLocationQuery();
            return <label style={{width: "40px"}} className="mb-0 text-nowrap">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
        }
    },
    {
        Header: "Name",
        accessor: "name",
        className: "bg-white",
        canSort: true,
        width: 400,
        Cell: ({ value, actions, row: { original } }) => {
            const { params } = useLocationQuery();
            const searchRequest = useMemo(() => params.search && params.search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH ? params.search : null, [params.search])
            const { decorateText } = useHighlight(searchRequest);

            return (
                <section onClick={() => actions[TAGS_ACTIONS.OPEN_DETAILS](original.id)} style={{width: "400px", overflow: "hidden", cursor: "pointer"}}>
                    <div {...decorateText(value)} className="text-truncate mb-0"/>
                </section>
            );
        }
    },
    {
        Header: "Actions",
        className: "bg-white",
        width: 100,
        Cell: ({
                   row: {
                       original: { id }
                   },
                   actions
               }) => {

            return <section
                className="d-flex gap-1 align-items-center"
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <Icon icon="edit"
                      width={20}
                      height={20}
                      className="me-4 cursor-pointer impacts-section__view"
                      onClick={(event) => {
                          event.stopPropagation();
                          actions[TAGS_ACTIONS.EDIT](id);
                      }}
                />

                <Icon
                    icon={"trashIcon"}
                    width={20}
                    height={20}
                    onClick={(event) => {
                        event.stopPropagation();
                        actions[TAGS_ACTIONS.DELETE](id);
                    }}
                    className="me-4 cursor-pointer"
                />
            </section>;
        }
    }
];