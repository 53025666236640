import yup from "../../../../../validation/yup";
import {
    MAX_SUBTITLE,
    MIN_STRING_LENGTH
} from "./constants";
import { MAX_TITLE, NAME_PATTERN, ORDER_MIN } from "./constants";
import { ENERGY_EQUATION, ENERGY_EQUATION_LABEL } from "../../../HealthProgram/Nutrition/CreateEditProgram/constants";
import { CONTENT_TYPE_API_ID, PROGRAM_PLAN_CONTENT_TYPE_LABELS } from "../../../HealthProgram/constants";
import { CONTENT_ASSIGNED } from "../../constants";
export const initialValues = {
    fullName: "",
    order: null,
    contentAssignedType: CONTENT_ASSIGNED.GENERAL
};

export const validationSchema = yup.object().shape({
    title: yup
      .string()
      .trim()
      .min(MIN_STRING_LENGTH)
      .max(MAX_TITLE)
      .matches(NAME_PATTERN, "Invalid title")
      .required("Is required"),
    subtitle: yup
      .string()
      .trim()
      .min(MIN_STRING_LENGTH)
      .max(MAX_SUBTITLE)
      .matches(NAME_PATTERN, "Invalid name")
      .required("Is required"),
    author: yup
      .mixed()
      .required("Is required"),
    contentType: yup
      .string()
      .required("Is required")
});

export const contentTypeOptions = Object.entries(CONTENT_TYPE_API_ID).map(([key, value]) => ({
    value,
    id: value,
    label: PROGRAM_PLAN_CONTENT_TYPE_LABELS[key]
}))