export const CONTENT_ASSIGNED = {
  GENERAL: 1,
  PROGRAMS: 2,
  SEGMENT: 3
}

export const CONTENT_ASSIGNED_LABELS = {
  [CONTENT_ASSIGNED.GENERAL]: "General content",
  [CONTENT_ASSIGNED.PROGRAMS]: "Programs",
  [CONTENT_ASSIGNED.SEGMENT]: "User segments"
}
